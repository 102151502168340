<template>
	<Confirmation :success="isSuccess()" success-title="forgot-password.title"
		success-subtitle="forgot-password.success" error-title="forgot-password.title"
		error-subtitle="forgot-password.error" try-again-link="/forgot-password"
		:show-back-to-dashboard="false" />
</template>

<script>
import { mapGetters } from 'vuex';
import Confirmation from '@/components/confirmation/Confirmation';

export default {
	name: 'ForgotPasswordConfirmation',
	components: {
		Confirmation,
	},
	data() {
		return {
			instructions: null,
		};
	},
	computed: {
		...mapGetters(['isEmbedded']),
	},
	methods: {
		getModuleStatus() {
			return this.$route.query.status;
		},
		isSuccess() {
			return this.getModuleStatus() === 'success';
		},
	},
};
</script>
